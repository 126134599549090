import React from 'react';
import Svg from './SVG';

const Menu = ({ fill }) => (
  <Svg viewBox="0 0 24 24" fill={fill}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </Svg>
);

export default Menu;