import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import {navigate} from "gatsby";
import React, {useState, useEffect, useContext} from 'react';
import Header from './Header'
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import Footer from './Footer';
import theme from '../../../styles/theme';
import GlobalStyles from "../../../styles/global";
import AuthContext from "../context/auth/context";
import isRouteProtected from "../context/auth/helpers/isRouteProtected";

const Layout = ({ children, tableOfContents, location, withoutSidebar = false, withoutFooter = false, withoutHeaderSearch = false }) => {
  const { user } = useContext(AuthContext);
  const [leftNavOpen, setLeftNavOpen] = useState(false);
  // const [rightNavOpen, setRightNavOpen] = useState(false);

  useEffect(() => {
    const siteWrapHeightChange = () => {
      const siteWrap = document.getElementById('site-wrap');
      const footerHeight = document.getElementById('footer')?.clientHeight;

      // console.log('footerHeight: ', footerHeight);

      siteWrap.style.minHeight = `calc(100vh - ${footerHeight}px - 1px)`;
    };
    
    const navListHeightChange = () => {
      const navList = document.getElementById('navList');
      const tocList = document.getElementById('tocList');
      const footerVisibleHeight = window.innerHeight - document.getElementById('footer')?.getBoundingClientRect().top
      
      if (footerVisibleHeight > 0 && navList && window.screen.width > 840)  {
        navList.style.height = `calc(100% - ${footerVisibleHeight}px - 24px - 1px)`;
      }
      if (footerVisibleHeight < 0 && navList)  {
        navList.style.height = `100%`;
      }
      if (footerVisibleHeight > 0 && tocList && window.screen.width > 1250)  {
        tocList.style.maxHeight = `calc(100vh - 64px - 24px - 26px - 24px - ${footerVisibleHeight}px - 1px)`;
      }
      if (footerVisibleHeight < 0 && tocList)  {
        tocList.style.maxHeight = `calc(100vh - 64px - 24px - 26px - 24px)`;
      }
    };  
  
    if (document.getElementById('footer')) {
      siteWrapHeightChange();
      navListHeightChange();

      window.addEventListener('resize', () => {
        siteWrapHeightChange();
        navListHeightChange();
      });
      window.addEventListener('scroll', () => {
        siteWrapHeightChange();
        navListHeightChange();
      });        
    }
  }, []);

  if (!user && isRouteProtected()) {
    navigate('/');
    return null;
  }

  const isDirectLink = location.pathname.startsWith('/direct');

  const withoutSidePanels = withoutSidebar && !tableOfContents.items;
  const withoutFooterVal = (user && !isDirectLink) ? withoutFooter : true;
  const withoutHeaderSearchVal = !isDirectLink ? withoutHeaderSearch : true;
  const withoutLoginBtn = isDirectLink;
  const showLogoTitle = !isDirectLink;
  const isLogoClickable = !isDirectLink;

  return (
    <>
      <GlobalStyles />
      <Header
          leftNavOpen={leftNavOpen}
          setLeftNavOpen={setLeftNavOpen}
          withoutSidebar={withoutSidebar}
          withoutHeaderSearch={withoutHeaderSearchVal}
          withoutLoginBtn={withoutLoginBtn}
          showLogoTitle={showLogoTitle}
          isLogoClickable={isLogoClickable}
      />
      {!withoutSidebar && <LeftSidebar leftNavOpen={leftNavOpen} setLeftNavOpen={setLeftNavOpen} location={location} />}
      <SiteWrap id="site-wrap" withoutSidePanels={withoutSidePanels}>
        {tableOfContents.items && (
          <RightSidebar tableOfContents={tableOfContents} location={location} />
        )}
        <SiteContentWrap withoutSidePanels={withoutSidePanels}>
          <SiteContent>{children}</SiteContent>
        </SiteContentWrap>
      </SiteWrap>
      {!withoutFooterVal && <Footer />}
    </>
  );
};

const SiteWrap = styled.div`
  display: ${props => props.withoutSidePanels ? 'block' : 'grid'};  
  flex-grow: 1;
  grid-gap: 24px;
  grid-template-columns: ${props => props.withoutSidePanels ? 'unset' : 'minmax(268px, 1fr) minmax(752px, 936px) minmax(160px, 1fr)'};
  
  padding-top: 64px;
  overflow-x: hidden;
  transition: ${() => theme.transition03};

  @media screen and (max-width: 1250px) {
    grid-template-columns: ${props => props.withoutSidePanels ? 'unset' : '268px 1fr 0'};
  }
  @media screen and (max-width: 840px) {
    display: block;

    min-width: 100%;
  }
`;

const SiteContentWrap = styled.div`
  grid-row: 1;
  grid-column: 2;
  align-self: start;
  min-width: 0;
  max-width: 936px;

  margin-right: ${props => props.withoutSidePanels ? 'auto' : 'unset'};
  margin-left: ${props => props.withoutSidePanels ? 'auto' : 'unset'};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SiteContent = styled.main`
  margin: 24px 0;

  padding: 40px;
  background: #fff;
  
  border-radius: 2px;
  
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3);
  animation-name: ${() => fadeIn}; 
  animation-duration: .5s; 
  animation-timing-function: linear;

  @media screen and (max-width: 1024px) {
    padding: 32px;
  }
  @media screen and (max-width: 840px) {
    padding: 24px;
  }
  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  tableOfContents: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;
