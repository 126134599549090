import React, { useState, useEffect, ReactNode } from 'react';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import { navigate } from 'gatsby';

import AuthContext, { AuthContextData } from './context';
import useTokenStorage from './helpers/useTokenStorage';
import useUserInfoStorage from './helpers/useUserInfoStorage';
import { AUTH_URL } from '../../../../utilities/utilities';
import isRouteProtected from "./helpers/isRouteProtected";

type Props = {
  children?: ReactNode;
};

const AuthProvider: React.FunctionComponent<Props> = ({ children }: Props) => {
  const { token, setToken } = useTokenStorage();
  const { user, setUser, updateUserInfo } = useUserInfoStorage();
  const [initialized, setInitialized] = useState<boolean>(false);

  const logout = (): void => {
    setToken(null);
    setUser(null);
  };

  const authorize = async (username: string, password: string): Promise<boolean> => {
    const response = await fetch(`${AUTH_URL}/api/login`, {
      body: JSON.stringify({ username, password }),
      method: 'POST',
    });
    const result = await response.json();
    const newToken = get(result, 'token');
    const newUser = get(result, 'user');

    if (newToken && newUser) {
      setToken(newToken);
      setUser(newUser);
      return true;
    } else {
      throw new Error('Request failed')
    }
  };

  const initialize = async (): Promise<void> => {
    try {
      const cookieToken = Cookies.get('kvint_token');
      const cookieUser = JSON.parse(localStorage.getItem('kvint_user') || 'null');
      setToken(cookieToken || null);
      setUser(cookieUser);

      if (cookieToken) {
        try {
          await updateUserInfo();
        } catch (e: any) {
          await logout();
        }
      } else {
        await logout();
      }
    } catch (e: any) {
      // nothing;
      // console.log(e);
    }
  };

  useEffect(() => {
    initialize().then(() => {
      setInitialized(true);
    });
  }, []);

  const contextState: AuthContextData = {
    token,
    user,
    authorize,
    logout,
    updateUserInfo,
  };

  if (!initialized) {
    return <></>;
  }

  if (!user && isRouteProtected()) {
    navigate('/');
  }

  return <AuthContext.Provider value={contextState}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
