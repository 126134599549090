import styled from 'styled-components';
import theme from '../../../../styles/theme';
import { Link } from 'gatsby';

export const BlogPost = styled(Link)`
  position: relative;
  z-index: 1;
  display: block;
  margin: 40px 0;
  padding: 16px 0;
  color: ${() => theme.blueDark1} !important;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%);
  cursor: pointer;
  transition: ${() => theme.transition03};
  user-select: none;

  @media screen and (max-width: 840px) {
    margin-right: -24px;
    margin-left: -24px;
  }
  @media screen and (max-width: 600px) {
    margin-right: -16px;
    margin-left: -16px;
  }

  &:after {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${() => theme.blueLight1};
    opacity: 0;
    transition: ${() => theme.transition03};
    content: "";    
  }
  &:hover {
    &:after {
      opacity: .1;
    }
  }
`;

export const BlogPostTitle = styled.p`
  margin: 0 0 16px 0;
  padding: 0 40px;
  color: ${() => theme.blueDark1};
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.02em;
`;

export const BlogPostBody = styled.div`
  position: relative;
  width: 100%;
  max-height: 120px;

  padding: 0 40px;

  overflow: hidden;

  ${() => theme.scrollbar};

  > *:first-child {
    margin-top: unset;
  }
  > *:last-child {
    margin-bottom: unset;
  }

  img {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #fff 0%, transparent 100%);
    content: "";
  }
`; 
