import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import * as JsSearch from 'js-search';
import { Input, Button } from 'antd';
import { isBrowser } from '../../../../utilities/utilities';
import { BlogPost } from './index.styled';
import ResultBody from './ResultBody';
import { SearchOutlined } from '@ant-design/icons';
import Seo from '../SEO';

// let dataToSearch: JsSearch.Search | null = null;
let dataToSearch = null;

const Search = () => {
  let searchParam;
  if (isBrowser) {
    const params = new URLSearchParams(window.location.search.substring(1));
    searchParam = params.get('value').toLowerCase() || '';
  }
 
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryWord, setSearchQueryWord] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchQueryHighlight, setSearchQueryHighlight] = useState('');

  const data = useStaticQuery(pageQuery);

  useEffect(() => {
    if (data.allMdx.nodes.length > 0) {
      setSearchQuery(searchParam);
      setSearchQueryWord(searchParam);
      setSearchResults(dataToSearch ? dataToSearch.search(searchParam) : [])
      setSearchQueryHighlight(searchParam);
    }
  }, [searchParam, data.allMdx.nodes.length, dataToSearch]);

  useEffect(() => {
    if (dataToSearch || !searchParam) {
      return;
    }

    console.log('start to search');

    const normalizedData = data.allMdx.nodes.map(node => ({
      id: node.fields.id,
      slug: node.fields.slug,
      title: node.fields.title,
      body: node.body,
      rawBody: node.rawBody,
    }));

    const idx = new JsSearch.Search('slug');
    idx.indexStrategy = new JsSearch.PrefixIndexStrategy()
    idx.sanitizer = new JsSearch.LowerCaseSanitizer()

    idx.addIndex("title")
    idx.addIndex("rawBody")

    idx.addDocuments(normalizedData);
    dataToSearch = idx;
  }, [data]);

  const onSearch = (evt) => {
    const { value } = evt.target;
    setSearchQuery(value);
  }

  const searchTerm = searchQuery.toLowerCase();

  return (
    <>
      <Seo title={searchQueryWord ? `${searchQueryWord} - Поиск` : "Поиск"} />
      <form onSubmit={(event) => {
        event.preventDefault();
        navigate(`/search?value=${searchTerm}`);
      }}>
        <Input.Group style={{ marginBottom: 24 }}>
          <Input 
            style={{ width: "calc(100% - 60px)", height: 44, fontSize: 20, }}
            placeholder="Введите ваш запрос..." 
            value={searchTerm} 
            onChange={onSearch} />
          <Button 
            style={{ width: 60, height: 44, fontSize: 20 }} 
            type="primary" 
            disabled={!searchTerm.length} 
            onClick={() => navigate(`/search?value=${searchTerm}`)}
          >
            <SearchOutlined />
          </Button>
        </Input.Group>
      </form>

      <h1>Результаты поиска:</h1>


      {searchResults.length > 0 ?
        (searchResults.slice(0, 5).map((item) => (
          <BlogPost
            to={`${item.slug}`}
            key={item.id}
          >
            <ResultBody searchTerm={searchQueryHighlight} data={item} />
          </BlogPost>
        )))
        : <h3>{!searchQueryWord.length > 0 ? "Задан пустой поиской запрос" : "Ничего не найдено :("}</h3>
      }
    </>
  );
};

export const pageQuery = graphql`
  query {
    allMdx(filter: { fields: { searchHidden: { eq: false } } }) {
      nodes {
        fields {
          id
          slug
          title
        }
        body
        rawBody
      }
    }
  }
`;

export { Search as default };