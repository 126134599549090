import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

/**
 * This react helmt code is adapted from
 * https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet.
 *
 * A great tutorial explaining how to setup a robust version of an
 * SEO friendly react-helmet instance.
 *
 *
 * Use the Helmt on pages to generate SEO and meta content!
 *
 * Usage:
 * <SEO
 *   title={title}
 *   description={description}
 *   image={image}
 * />
 *
 */

const seoQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            title
            name
            siteUrl
          }
        }
      }
    }
  }
`;

const SEO = ({ title, url, pathname }) => { 
  const results = useStaticQuery(seoQuery);
  const site = results.allSite.edges[0].node.siteMetadata;

  const fullURL = path => (path ? `${site.siteUrl}${path}` : site.siteUrl);

  // const pageTitle = title ? `${title} | ${site.title}` : site.title;
  const pageTitle = title || site.title;

  const metaTags = [
    { charset: 'utf-8' },
    {
      'http-equiv': 'X-UA-Compatible',
      content: 'IE=edge'
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    },
    {
      rel: 'canonical',
      href: fullURL(pathname)
    },
    { itemprop: 'name', content: pageTitle },

    { property: 'og:title', content: pageTitle },
    { property: 'og:url', content: url },
    { property: 'og:site_name', content: site.siteName },
    { property: 'yandex-verification', content: '47f6a4cb145c74d3' },
  ];

  return <Helmet title={pageTitle} htmlAttributes={{ lang: 'ru' }} meta={metaTags} />;
};

SEO.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  pathname: PropTypes.string
};

SEO.defaultProps = {
  title: '',
  url: '',
  pathname: ''
};

export default SEO;
