import { createGlobalStyle } from "styled-components"
import theme from './theme';

import './vendors/_reset.less';
import './vendors/_settings.less';

import './partials/_button.less';
import './partials/_input.less';

import './fonts/RobotoMono/stylesheet.less';
import './fonts/Roboto/stylesheet.less';
import './fonts/MaterialIcons/stylesheet.less';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a.anchor-link', {
    topOnEmptyHash: true,
    speed: 700,
    speedAsDuration: true,
    offset: 100,
    updateURL: true, // Update the URL on scroll
  })
}

const GlobalStyles = createGlobalStyle`
  :root {
    --blueLight1: #01c8f8;
    --bluelight2: #00b3d9;
    --bluelight3: #00beec;
    --bluelight4: #00b6e2;
    --blueDark1: #0f213c;
    --red1: #ff0000;
    --blueGradient1: linear-gradient(#00B3D9, #00E8FD);  
    --transition03: .3s ease-in-out;
    --transition05: .5s ease-in-out;
  }

  body {
    /* font-family: -apple-system, BlinkMacSystemFont, San Francisco, Helvetica Neue, Helvetica, Ubuntu, Roboto, Noto, Segoe UI, Arial, sans-serif; */
    color: ${() => theme.blueDark1};
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 1.2;
    
    background: ${() => theme.bodyBackground};

    &.unscroll {
      overflow: hidden;
    }
  }
  
  h1 {
    margin-bottom: 16px;
    
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 355px) {
      font-size: 24px;
    }
  }
  h2 {
    margin-top: 40px;
    margin-bottom: 24px;

    font-size: 24px;
    font-weight: 600;

    &:hover {
      a.copy-article-link {
        visibility: visible;
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
  h3 {
    margin-top: 32px;
    margin-bottom: 16px;

    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    margin-top: 24px;
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 16px;
  }
  p {
    position: relative;

    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    margin: 16px 0;
  }
  pre {
    position: relative;

    padding: 24px;

    tab-size: 2;

    font-family: 'Roboto Mono', sans-serif;
    font-style: normal;
    color: #37474f;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;

    background: #f1f3f4;

    overflow: auto;

    @media screen and (max-width: 1024px) {
      padding: 32px;
    }
    @media screen and (max-width: 840px) {
      padding: 24px;
    }
    @media screen and (max-width: 600px) {
      padding: 16px;
    }
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  table {

    border-width: 2px;
    border-style: solid;
    border-color: #dee2e6;

    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, .05);
        }
      }
    }
    th, 
    td {
      padding: 12px;

      line-height: 1.4;

      border-right-width: 2px;
      border-right-style: solid;
      border-right-color: #dee2e6;

      &:last-child {
        border: none;
      }
    }
    th {
      font-weight: 800;
    }
    td {
      font-weight: 400;

      ul {
        margin: 8px 0;
        padding-left: 32px;

        list-style: unset;

        li {
          padding: 2px 0;
        }
      }
    }
  }
  ul, ol {
    padding-inline-start: 40px;

    li {
      position: relative;
      
      margin: 10px 0;

      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  a.copy-article-link {
    &.before {
      position: absolute;
      top: 0;
      right: 100%;
      padding-right: 4px;
    }
    
    padding-left: 4px;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    fill: ${() => theme.blueDark1};
    
    transition: ${() => theme.transition};
    
    &:hover {
      fill: ${() => theme.blueLight1};
    }
  }
  /* .gatsby-resp-image {
    &-wrapper {
      margin-left: unset !important;
      max-width: unset !important;
    }
    &-background-image {
      padding-bottom: unset !important;
      background-image: unset !important;
    }
    &-image {
      position: relative !important;

      left: 50% !important;

      width: unset !important;
      max-width: 100%;
      height: unset !important;

      vertical-align: middle;

      transform: translateX(-50%);
    }
  } */
  /* https://github.com/gatsbyjs/gatsby/issues/15486 */
  /* .gatsby-resp-image-image {
    width: 100%;
    height: 100%;
    margin: 0;
    vertical-align: middle;
  } */

  p {
    &.notice,
    &.caution,
    &.warning {
      margin-right: -40px;
      margin-left: -40px;
      padding: 16px 40px;
      padding-top: 16px;
      padding-right: 40px;
      padding-bottom: 16px;
      padding-left: 76px;

      &::before {
        position: relative;

        color: inherit;
        font-size: inherit;
        font-weight: 700;
        line-height: inherit;
      }
      &::after {
        position: absolute;

        top: 16px;
        left: 40px;
        
        font-family: 'Material Icons';
        font-weight: normal;
        font-size: 24px;
        line-height: 1;
      }
      a {
        position: relative;

        color: inherit;
        font-weight: 500;

        &::before,
        &::after {
          position: absolute;

          top: 100%;

          width: 50%;
          height: 1px;

          content: '';

          transition: ${() => theme.transition};
        }
        &::before {
          right: 50%;
        }
        &::after {
          left: 50%;
        }
        &:hover {
          &::before, 
          &::after {
            width: 0%;
          } 
        }
      }
    }
    &.notice {
      color: ${() => theme.noticeColor};

      background: ${() => theme.noticeBackground};

      &::before {
        content: "Примечание: ";
      }
      &::after {
        content: "star";
      }
      a {
        &::before, &::after {
          background: ${() => theme.noticeColor};
        }
      }
    }
    &.caution {
      color: ${() => theme.cautionColor};
      
      background: ${() => theme.cautionBackground};
      
      &::before {
        content: "Важно! ";
      }
      &::after {
        content: "error";
      }
      a {
        &::before, &::after {
          background: ${() => theme.cautionColor};
        }
      }
    }
    &.warning {
      color: ${() => theme.warningColor};
      
      background: ${() => theme.warningBackground};
      
      &::before {
        content: "Опасно: ";
      }
      &::after {
        content: "warning";
      }
      a {
        &::before, &::after {
          background: ${() => theme.warningColor};
        }
      }
    }
  }
  mark {
    background-color: yellow;
  }
  .medium-zoom {
    &-overlay {
      background: ${() => theme.blueDark1} !important;
    }
  } 

  /* span.gatsby-resp-image-wrapper {
    cursor: zoom-in;
  } */
  .img__popup {
    position: fixed;

    z-index: 999;

    top: 50%;
    left: 50%;

    width: 100%;
    min-width: 100%;
    max-width: 100%;

    height: 100%;
    min-height: 100%;
    max-height: 100%;

    margin-right: -50%;

    transform: translate(-50%, -50%);

    background: ${() => theme.blueDark1};

    transition: ${() => theme.transition03};

    overflow: auto;

    ${() => theme.invisible};

    &.show {
      ${() => theme.visible};
    }
    > * {
      cursor: zoom-out !important;

      top: 50%; 
      left: 50%;

      margin-right: -50% !important;
      margin-left: unset !important;

      transform: translate(-50%, -50%);

      @media screen and (max-width: 900px) and (orientation: portrait) {
        max-width: 98% !important;
        max-height: 98% !important;
      }
      @media screen and (max-width: 900px) and (orientation: landscape)  {
        max-width: 95% !important;
        max-height: 95% !important;
      }
    }
  }

  /* h1[style="color: rgb(255, 255, 255);"],
  h4[style="color: rgb(255, 255, 255);"],
  a[href="https://gitlab.com/mkit/open-source/gatsby-theme-password-protect#readme"],
  a[href="https://mkit.io"] {
    display: none;
  }  */

  table {
    display: block;
    width: fit-content;

    ${() => theme.scrollbar};

    thead {
      tr {
        background-color: rgba(0, 0, 0, .10);

        th {
          font-size: 16px;
          vertical-align: middle;
        }
      }
    }
    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: rgba(0, 0, 0, .05);
        }
        &:nth-of-type(odd) {
          background-color: transparent;
        }
        td {
          font-size: 14px;
        }
      }
    }
  }

  div[style="height:100vh;background:#424242;display:flex;flex-direction:column;justify-content:center;align-items:center"] {
    height: unset !important;
    background: unset !important;
    display: unset !important;
    flex-direction: unset !important;
    justify-content: unset !important;
    align-items: unset !important;
    
    display: block !important;   
    flex-grow: 1 !important;
    grid-gap: 24px !important;
    
    padding-top: 64px !important;
    overflow-x: hidden !important;
    transition: ${() => theme.transition03} !important;

    @media screen and (max-width: 840px) {
      min-width: 100% !important;
    }
  }
  
  span.gatsby-resp-image-wrapper {
    max-height: 400px;
    overflow: hidden;
    
    img {
      object-fit: contain;
    }
  }
`;

export default GlobalStyles;
