import React from 'react';
import { Tooltip } from "antd";
import { hints } from '../../../../content';

const Span = ({ children, ...rest }) => {
    if (rest['data-hint-id'] && hints[rest['data-hint-id']]) {
        return (
            <Tooltip title={hints[rest['data-hint-id']]}>
                <span style={{ textDecoration: 'underline dotted gray', textUnderlineOffset: '4px' }}>
                    {children}
                </span>
            </Tooltip>
        );
    }

    return <span {...rest}>{children}</span>;
}

export default Span;
