import React from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import { Link } from "gatsby";

const Footer = () => {
  const mapFooter = [
    {
      title: "Главная",
      link: "",
    },
    {
      link: "get-started",
      title: "Голосовые роботы",
    },
    {
      link: "user_guide",
      title: "Руководство пользователя",
    },
    {
      link: "api_kvint",
      title: "Kvint API",
    },
    {
      link: "integrations",
      title: "Интеграции",
    },
    {
      link: "for_developers",
      title: "Для разработчиков",
    },
    {
      link: "support",
      title: "Для техподдержки",
    },
    {
      link: "release_notes",
      title: "Релизы",
    },
    {
      link: "kvint-personal-account",
      title: "Личный кабинет",
    },
    {
      link: "sales",
      title: "Продажи",
    },
  ];

  return (
    <FooterStyled id="footer">
      <FooterInner>
        {mapFooter.map((item) => (
          <FooterLink to={`/${item.link}`} key={item.link}>{item.title}</FooterLink>
        ))}
      </FooterInner>
    </FooterStyled>
  );
};

const FooterStyled = styled.footer`
  position: relative;
  width: 100%;
  background: ${() => theme.blueDark1};

  @media screen and (min-width: 1024px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 1024px) and (min-width: 800px) {
    padding: 32px;
  }
  @media screen and (max-width: 800px) and (min-width: 600px) {
    padding: 30px;
  }
  @media screen and (max-width: 600px) {
    padding: 28px;
  }
`; 
const FooterInner = styled.div`
  display: flex;
  
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1400px) {
    gap: 32px;
  }
  @media screen and (max-width: 1400px) and (min-width: 1300px) {
    gap: 30px;
  }
  @media screen and (max-width: 1300px) and (min-width: 1200px) {
    gap: 28px;
  }
  @media screen and (max-width: 1200px) and (min-width: 1100px) {
    gap: 26px;
  }
  @media screen and (max-width: 1100px) and (min-width: 1025px) {
    gap: 24px;
  }
  @media screen and (min-width: 1024px) {
    height: 64px;
  }
  @media screen and (max-width: 1024px) and (min-width: 800px) {
    gap: 32px;
  }
  @media screen and (max-width: 800px) and (min-width: 600px) {
    gap: 30px;
  }
  @media screen and (max-width: 600px) {
    gap: 28px;
  }
`;
const FooterLink = styled(Link)`
  position: relative;
  color: ${() => theme.blueLight1};
  font-weight: 500;
  line-height: 1.2;
  transition: ${() => theme.transition03};

  @media screen and (min-width: 1400px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1400px) and (min-width: 1300px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1300px) and (min-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1200px) and (min-width: 1100px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1100px) and (min-width: 1025px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }   

  &::before,
  &::after {
    position: absolute;
    
    top: 100%;
    width: 50%;
    height: 1px;
    background: ${() => theme.blueLight1};
    transition: ${() => theme.transition03};
    content: '';
  }
  &:before {
    right: 50%;
  }
  &::after {
    left: 50%;
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      color: ${() => theme.white};

      &:before,
      &::after {
        width: 0%;
        background: ${() => theme.white};
      }
    }
  }
`;

export default Footer;