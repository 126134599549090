import React, {useContext, useMemo} from "react"
import Layout from "../components/docs-theme/components/Layout";
import { Link } from "gatsby";
import { contentDirectories } from "../content";
import Seo from '../components/docs-theme/components/SEO';
import AuthContext from "../components/docs-theme/context/auth/context";

const IndexPage = ({ location }) => {
  const { user } = useContext(AuthContext);
  const filteredContentDirectories = useMemo(() => {
  	if (!user) {
  	  return contentDirectories.filter(item => !item.protected);
	}

  	return contentDirectories;
  }, [user]);

  return (
    <Layout
      tableOfContents={[]}
      location={location}
      withoutSidebar
      withoutFooter
    >
      <Seo title={"Главная"} />
      <h1>Документация Kvint.io</h1>
      {filteredContentDirectories.map((item) => (
        <p key={item.link}>
          <Link to={`/${item.link}`}>{item.title}</Link>
        </p>
      ))}
    </Layout>
  );
}

export default IndexPage;
