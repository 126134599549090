// Array<{ link: string; title: string; protected: boolean }>
const contentDirectories = [
    {
        link: "get-started",
        title: "Проектирование и разработка голосовых роботов KVINT",
        protected: true,
    },
    {
        link: "user_guide",
        title: "Практическое руководство по созданию голосовых роботов",
        protected: true,
    },
    {
        link: "api_kvint",
        title: "Документация KVINT API",
        protected: false,
    },
    {
        link: "integrations",
        title: "Интеграции",
        protected: true,
    },
    {
        link: "for_developers",
        title: "Документация для разработчиков",
        protected: true,
    },
    {
        link: "support",
        title: "FAQ для технической поддержки",
        protected: true,
    },
    {
        link: "release_notes",
        title: "Информация о релизах",
        protected: true,
    },
    {
        link: "kvint-personal-account",
        title: "Личный кабинет KVINT",
        protected: false,
    },
    {
        link: "sales",
        title: "Продажи и маркетинг",
        protected: true,
    },
];

/*
* 1. Все страницы, чей адрес начинается со значения из массива searchHiddenLinks, будут полностью убраны из поиска
* 2. Также можно использовать внутри контента переменную `searchHidden: true` или `searchHidden: false`,
* чтобы спрятать/отобразить страницу в поиске:
* ```
    ---
    title: "Выгрузка отчётов по попыткам"
    linkTitle: "Выгрузка отчётов по попыткам"
    weight: 4
    searchHidden: false
    ---
* ```
* 3. Переменная searchHidden имеет приоритет над searchHiddenLinks.
* */
const searchHiddenLinks = ['/direct/', '/top_secret_meta_info/'];

/*
* Пример использования внутри контента: <span data-hint-id="intent">интента</span>
* 1. intent - левое значение из переменной hints
* 2. в тултипе отобразится правое значение, соответствующее значению intent
* */
const hints = {
    action: 'Элемент "Action" в редакторе скрипта, позволяющий роботу выполнять действия (отправлять смс, записывать данные в филд, обращаться к Lua-скрипту и т.д.)',
    additional_number: 'Число, которое абонент должен ввести после установления соединения с роботом, чтобы переадресовать звонок на необходимую входящую кампанию или группу кампаний',
    aggregation: 'Процесс сбора, обработки и представления информации в окончательном виде',
    alert: 'Уведомление о возможной ошибке или проблеме',
    alias: 'Контейнер внутри скрипта, позволяющий разместить в нём NLU-модель для робота',
    aon: 'Телефонный номер',
    anyevent: 'Cобытие или блок скрипта, которое может быть вызвано на любом этапе диалога и соответствует определённому сценарию (просьба перезвонить, отказ абонента говорить с роботом и т.д.)',
    api: 'Программный интерфейс приложения. Набор способов и правил, по которым системы взаимодействуют между собой и обмениваются данными',
    batch: 'Небольшой пакет данных одной итерации',
    billing: 'Комплекс процессов и решений, ответственных за сбор информации об использовании телекоммуникационных услуг, их тарификацию, выставление счетов абонентам, обработку платежей',
    build: 'Сборка всех настроенных компонентов робота для размещения на сервере',
    caller: 'Внутренний сервис для запуска заданий в прозвон, приёма входящих звонков и получения результатов прозвона',
    campaign: 'Набор правил и настроек для робота, которые применяются при обзвоне загруженной базы абонентов или при ответе на входящие звонки',
    core: 'Внутренний сервис для воспроизведения логики скриптов и совершения действий в ходе диалога робота и абонента',
    client: 'Сервис, отправляющий запрос',
    cps: 'Cкорость совершения звонков - количество звонков в секунду',
    demostand: 'Входящая кампания, предназначенная для демонстрации и тестирования возможностей голосового робота',
    deploy: 'Процесс размещения собранного робота (билда) на сервере',
    dialer: 'Внутренний сервис для совершения исходящего дозвона и запуска робота',
    em: 'Сервис определения аудиопотока на первых стадиях звонка (до установления соединения)',
    field: 'Ячейка для хранения информации в скрипте',
    ftp: 'Протокол передачи файлов по сети',
    function: 'Группа операторов (команд), которые вместе выполняют определённую задачу',
    generator: 'Подпрограмма, способная возвращать очередное значение и автоматически сохранять и возобновлять своё состояние для возврата следующего значения.',
    instance: 'Среда базы данных в облаке, вычислительные ресурсы и емкость хранилища которой определяет пользователь',
    integration: 'Объединение независимо разработанных программных приложений с целью обеспечения их совместной работы',
    intent: 'Намерение пользователя в диалоге. Например, фраза пользователя «Хорошо, подключайте» выражает его согласие и будет определена роботом как интент «soglasie»',
    lemmatization: 'Процесс приведения словоформы к лемме — её нормальной (словарной) форме. Например, в результате лемматизации слово «идут» будет приведено к форме «идти»',
    log: 'Запись событий в хронологическом порядке, детализация',
    lua: 'Скрипт на языке Lua, с помощью которого робот может в ходе диалога выполнять различные действия: записывать номер телефона, отправлять результат звонка по API и т.д.',
    mapping: 'Соотнесение системных статусов диалога с пользовательскими статусами',
    message: 'Элемент "Message", содержащий реплики робота в редакторе скрипта',
    migration: 'Описание изменений, которые необходимо внести в таблицы базы данных',
    nlu: 'Система распознавания интентов (намерений) в речи абонента',
    nlu_model: 'Сборка всех интентов и слотов, задействованных в скрипте',
    orm: 'Способ доступа к реляционной базе данных с помощью объектно-ориентированного языка программирования (например, Java)',
    optimisation: 'Выбор наилучшего варианта решения из множества возможных',
    parsing: 'Автоматизированный сбор и структурирование данных при помощи программы или сервиса - парсера',
    property: 'Набор обучающих примеров (фраз) для интента',
    registry: 'База данных, содержащая телефонные номера абонентов и их дополнительные данные: внешние идентификаторы, таймзоны, ФИО абонентов, метки для интеграции и т.д.',
    relational_db: 'Набор данных, организованных в виде набора таблиц, с предопределёнными связями между ними',
    script: 'Логическая последовательность ответов робота на реплики абонента',
    server: 'Ресурс телефонии, предназначенный для управления передачей голосовых телефонных вызовов, а также других медиапотоков через сеть коммутации пакетов, в т. ч. по протоколу IP.',
    server_connectivity: 'Состояние, при котором один сервер способен обнаружить другой, например, сервер KVINT «видит» сервер оператора связи',
    sip: 'Протокол установления сеанса. Один из протоколов передачи голоса в сети',
    sl: 'Соединение между роботом и абонентом (звонок)',
    slot_nlu: 'Именованнные cущности, выделяемые из речи пользователя (названия городов, имена, числа, время перезвона и т.д.)',
    slot_telephony: 'Ресурс телефонии, контейнер, размещённый на сервере',
    stage: 'Тестовое окружение',
    state: 'Элемент "State" в редакторе скрипта',
    stt: 'Преобразование устной речи в текст',
    task: 'Задание для робота на дозвон одному клиенту',
    test_set: 'Набор эталонных обучающих примеров для проверки качества NLU-модели',
    timestamp: 'Временна́я метка, последовательность символов или закодированной информации в базе данных, показывающей, когда произошло определённое событие',
    timezone: 'Часовой пояс',
    transit: 'Тип прозвона, при котором транк не соответствует звонящему серверу. Например, кампания размещена на транке mtt 29, но на сервере 44, поскольку при запуске кампании слот, указанный в транке, был занят. Такая кампания будет называться транзитной.',
    transit_campaign: 'Кампания прозвона, транк и сервер которой не совпадают',
    trunk: 'Канал связи между системой прозвона KVINT и партнёрами, которые предоставляют услуги телефонии',
    tts: 'Преобразование текста в устную речь (синтез)',
    vad: 'Модуль обнаружения голосовой активности во входном акустическом сигнале для отделения активной речи от фонового шума или тишины. Определяет время начала (VAD-start) и конца (VAD-stop) фразы'
};

module.exports = {
    contentDirectories,
    searchHiddenLinks,
    hints,
};
