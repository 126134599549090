import Cookies from 'js-cookie';

const getToken = (): string | null => {
  try {
    const cookieToken = Cookies.get('kvint_token');
    return cookieToken || null;
  } catch (e: any) {
    return null;
  }
};

export default getToken;
