import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import Arrow from '../icons/Arrow';
import Icon from '../icons/Icon';

// onClick,
const ButtonCollapse = ({  isCollapsed }) => {
  return (
    <StyledButtonCollapse
      // onClick={onClick}
      aria-label="Toggle Subnavigation"
      title="Toggle Subnavigation" 
    >
      <Icon icon={<Arrow />} size={24} className={!isCollapsed && 'rotateArrow'} />
    </StyledButtonCollapse>
  );
}; 

const StyledButtonCollapse = styled.button`
  position: absolute;

  top: 50%;
  right: 100%;
  margin: 0 4px;
  padding: 0;
  background: none;
  
  border: 0;
  transform: translateY(-50%);
  
  cursor: pointer;
`;

ButtonCollapse.propTypes = {
  // onClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool
};

export default ButtonCollapse;
