// auth provider module

import { useState } from 'react';
import { get } from 'lodash';
import { User } from '@kvint/portal-auth';
import getToken from "./getToken";
import { AUTH_URL } from '../../../../../utilities/utilities';

type UserStorage = {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUserInfo: () => Promise<void>;
};

const useUserInfoStorage = (): UserStorage => {
  const [user, setUser] = useState<User | null>(null);

  const setNewUser = (newUser: User | null): void => {
    if (newUser) {
      localStorage.setItem('kvint_user', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('kvint_user');
    }
    setUser(newUser);
  };

  const updateUserInfo = async (): Promise<void> => {
    const response = await fetch(`${AUTH_URL}/api/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
    });
    const result = await response.json();
    const updatedUser = get(result, 'user');
    if (updatedUser) {
      setNewUser(updatedUser);
    } else {
      throw new Error('Request failed');
    }
  };

  return { user, setUser: setNewUser, updateUserInfo };
};

export default useUserInfoStorage;
