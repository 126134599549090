import React from "react";
import Layout from "../components/docs-theme/components/Layout";
import LoginComponent from "../components/docs-theme/components/Login";

const Login = ({ location }) => (
    <Layout tableOfContents={[]} location={location} withoutSidebar withoutFooter withoutHeaderSearch>
        <LoginComponent />
    </Layout>
);

export default Login;