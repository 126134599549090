import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { uniqueId } from 'lodash';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import MdxLink from '../components/MDX/Link';
import MdxSpan from '../components/MDX/span';
import MdxTable from '../components/MDX/table';
import { isBrowser } from '../../../utilities/utilities';
import { getSimilarWords } from '../../../utilities/search';
import { MDXProvider } from '@mdx-js/react';


const DocsTemplate: React.FC = ({ data, location }) => {
  const { mdx } = data;

  const [similarWords, setSimilarWords] = useState([]);

  let searchTerm = '';
  if (isBrowser) {
    const searchParam = new URLSearchParams(window.location.search.substring(1)).get('search');
    if (searchParam !== null) {
      searchTerm = searchParam;
    }
  }

  useEffect(() => {
    setSimilarWords(getSimilarWords(searchTerm, mdx.rawBody));
  }, [searchTerm]);

  // useEffect(() => {
  //   let imgWrap = document.querySelectorAll('p > span.gatsby-resp-image-wrapper'); 
  //   let imgPopup = document.createElement('div');
  //   imgPopup.className = "img__popup";
  
  //   const onHideImg = () => {
  //     imgPopup?.classList.remove('show');
  
  //     setTimeout(() => { 
  //       if (imgPopup?.firstChild) { imgPopup.removeChild(imgPopup?.firstChild) }
  //       if (document.body.contains(imgPopup)) { document.body?.removeChild(imgPopup) }
  //     }, 300);
  //   }
  //   for (let i = 0; i < imgWrap.length; i++) {
  //     imgWrap[i].addEventListener("click", function() {
  //       document.body.appendChild(imgPopup);
  //       imgPopup.appendChild(imgWrap[i].cloneNode(true));
  //       setTimeout(() => { imgPopup?.classList.add('show') }, 0);
  //     }, true);
  //   }
  //   if (imgPopup) {
  //     // imgPopup.addEventListener('click', (event) => {
  //     //   event.preventDefault();
  //     //   imgPopup.firstChild?.addEventListener('mousedown', () => {
  //     //     onHideImg()
  //     //   })
  //     // }, true)

  //     imgPopup.addEventListener('click', (event) => {
  //       event.preventDefault();
  //       onHideImg();
  //     }, true)
  //   }
  //   document.addEventListener('keydown', (e) => { 
  //     if (e.key === "Escape") { onHideImg() } 
  //   }, true)
  // }, [])
  
  const highlightWords = (childrenProp) => {
    const result = [];
    React.Children.forEach(childrenProp, (child) => {
      if (child.props && child.props.children) {
        const newChild = React.cloneElement(child, {
          children: highlightWords(child.props.children),
          key: uniqueId(),
        });
        result.push(newChild);
      } else if (!child.props) {
        const parts = child.split(new RegExp(`(${similarWords.join('|')})`, 'gi'));
        const highlightedChild = parts.map((part) => {
          if (part !== '') {
            if (similarWords.includes(part.toLowerCase())) {
              return (
                <mark className="highlight" key={uniqueId()}>
                  {part}
                </mark>
              );
            }
            return part;
          }
          return null;
        });
        result.push(highlightedChild);
      } else {
        result.push(child);
      }
    });
    return result;
  };

  const mdxComponents = {
    wrapper: searchTerm === '' ? null : ({ children: mdxChildren }) => <>{highlightWords(mdxChildren)}</>,
    a: MdxLink,
    span: MdxSpan,
    table: MdxTable,
  };

  useEffect(() => {
    const highlights = document.querySelectorAll('.highlight');
    if (highlights[0]) {
      highlights[0].scrollIntoView({ block: "center", behavior: "smooth"});
    }
  }, [searchTerm]);

  return (
    <Layout tableOfContents={mdx.tableOfContents} location={location}>
      <SEO title={mdx.fields.title || "Главная"} />
      {/* <h1>{location}</h1> */}
      <h1>{highlightWords(mdx.fields.title)}</h1>
      <MDXProvider components={mdxComponents}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  );
};

export const pageQuery = graphql` 
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      tableOfContents
      fields {
        title
      }
      rawBody
    }
  }
`;

export default DocsTemplate;
