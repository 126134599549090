import React from 'react';
import DoubleScrollbar from 'react-double-scrollbar';

const Table = ({ children }) => {
    return (
        <div style={{ marginBottom: '32px' }}>
            <DoubleScrollbar>
                <table>{children}</table>
            </DoubleScrollbar>
        </div>
    )
}

export default Table;
