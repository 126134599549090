import React, { useState } from "react";
import { Input, Button, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby';
import styled from 'styled-components';

const StyledInputGroup = styled(Input.Group)`
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
`;
const StyledButton = styled(Button)`
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
`;

const HeaderSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      <Form onSubmitCapture={() => {
        if (searchQuery.length) {
          navigate(`/search?value=${searchQuery}`)
        }
      }}>
        <StyledInputGroup compact style={{ width: 300 }}>
          <Input style={{ width: 260 }} placeholder="Введите ваш запрос..." value={searchQuery} onChange={(evt) => setSearchQuery(evt.target.value)} />
          <Button style={{ width: 40, fontSize: 18 }} type="primary" disabled={!searchQuery.length} onClick={() => navigate(`/search?value=${searchQuery}`)}>
            <SearchOutlined />
          </Button>
        </StyledInputGroup>
      </Form>
      <StyledButton style={{ width: 40, fontSize: 18 }} type="primary" onClick={() => navigate(`/search?value=${searchQuery}`)}><SearchOutlined /></StyledButton>
    </>
  );
};

export default HeaderSearch;