import React, {useContext, useState} from "react"
import { Form, Input, Button, Row, Col, message } from 'antd';
import { navigate } from "gatsby"
import AuthContext from "../../context/auth/context";

const Login = () => {
    const { user, authorize } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    if (user) {
        navigate(`/`);
        return null;
    }

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            await authorize(values.username, values.password);
        } catch (err) {
            console.error(err);
            message.error('Failed to login!');
        } finally {
            setLoading(false);
        }
    };

    return (
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="true"
      >
          <Row>
              <Col offset={4}>
                  <h4>Log In</h4>
              </Col>
          </Row>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
              <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
              <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
              </Button>
          </Form.Item>
      </Form>
    );
}

export default Login