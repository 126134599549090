import React from 'react';
import styled from 'styled-components';

const Icon = ({ icon, size, className }) => <StyledIcon size={size} className={className}>{icon}</StyledIcon>;

const StyledIcon = styled.span`
  display: inline-block;
  
  width: ${p => `${p.size}px`};
  height: ${p => `${p.size}px`};

  &.rotateArrow {
    > svg {
      transform: rotate(90deg);  
    }
  }
`;

export default Icon;
