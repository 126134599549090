const theme = {
  transition03: '.3s ease-out',
  transition05: '.5s ease-out',
  
  bodyBackground: '#E8EAED',
  noticeBackground: '#e1f5fe',
  sidebarBackground: '#F5F5F5',
  
  primary: '#e63b19', 
  secondary: '#c70d3a',

  noticeColor: '#015798',

  cautionBackground: "#feefe3",
  cautionColor: "#bf360c",

  warningBackground: "#fce8e6",
  warningColor: "#d50000",

  blueLight1: '#01c8f8',
  bluelight2: '#00b3d9',
  bluelight3: '#00beec',
  bluelight4: '#00b6e2',
  blueDark1: '#0f213c',

  textdefault: '#0f213c',
  textblue: '#01c8f8',

  red: '#ef0000',

  white: '#fff',
  white1: '#f9f9f9',
  white2: '#f5f5f5',
  white3: '#f1f1f1',
  white4: '#dfdfdf',

  gray: '#808080',
  gray1: '#8b8b8b',
  gray2: '#b8b7b7',
  gray3: '#acaaaa',

  black: '#000',

  visible: `
    visibility: visible; 
    opacity: 1; 
    pointer-events: visible;`,

  invisible: `
    visibility: hidden; 
    opacity: 0; 
    pointer-events: none;`,

  scrollbar: `
    scrollbar-color: #01c8f8 transparent; 
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;

      @media screen and (max-width: 1024px) {
        width: 8px;
        height: 8px;
      }
      @media screen and (max-width: 700px) {
        width: 6px;
        height: 6px;
      } 
      @media screen and (max-width: 480px) {
        width: 4px;
        height: 4px;
      } 
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #01c8f8;
      box-shadow: inset 1px 1px 6px #fff;

      @media screen and (max-width: 1024px) {
        width: 8px;
        height: 8px;
      }
      @media screen and (max-width: 700px) {
        width: 6px;
        height: 6px;
      } 
      @media screen and (max-width: 480px) {
        width: 4px;
        height: 4px;
      }
      &:hover {
        background: #00b3d9;
      }
    }
  `,
};

export default theme;
