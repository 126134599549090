// auth provider module

import { useState } from 'react';
import Cookies from 'js-cookie';

type TokenStorage = { token: string | null; setToken: (token: string | null) => void };

const useTokenStorage = (): TokenStorage => {
  const [token, setToken] = useState<string | null>(null);

  const setNewToken = (newToken: string | null): void => {
    if (newToken) {
      Cookies.set('kvint_token', newToken, { expires: 5 });
    } else {
      Cookies.remove('kvint_token');
    }
    setToken(newToken);
  };

  return { token, setToken: setNewToken };
};

export default useTokenStorage;
