import {contentDirectories} from "../../../../../content";
import {isBrowser} from "../../../../../utilities/utilities";

const isRouteProtected = () => {
  if (!isBrowser) {
    return false;
  }

  const route = window.location.pathname.split('/').filter(Boolean)[0];
  const directory = contentDirectories.find(item => item.link === route);

  return directory ? directory.protected : false;
};

export default isRouteProtected;
