import React from 'react';
import styled from 'styled-components';
import theme from '../../../../styles/theme';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

const RightSidebar = ({ tableOfContents, location }) => {

  // const [listItems] = useState(() => {
  //   if (!tableOfContents.items) {
  //     return [];
  //   }
  //   const mappedLinks = [];

  //   function mapLinks(items) {
  //     items.forEach(item => {
  //       mappedLinks.push(item);
  //       if (item.items) {
  //         mapLinks(item.items);
  //       }
  //     });
  //   }

  //   mapLinks(tableOfContents.items);

  //   return mappedLinks;
  // });


  // console.log(tableOfContents.items.map(item => item.items));
  // console.log(tableOfContents.items);
  // console.log(Object.entries(tableOfContents.items.map(item => item.items)))

  // const tocListItems = tableOfContents.items.map(item => item.items) || tableOfContents.items;
 
  return (
    <RightSidebarWrap>
      <RightSidebarList>
        <RightSidebarTitle>Содержание</RightSidebarTitle>
        <RightSidebarListWrap id="tocList">
          {tableOfContents.items.map(item => (
            <RightSidebarListItem key={location.pathname + item.url}>
              <ListItem
                location={location}
                item={item} />
            </RightSidebarListItem>
          ))}
        </RightSidebarListWrap>
      </RightSidebarList>
    </RightSidebarWrap>
  );
};

const RightSidebarWrap = styled.aside`
  display: none;
  grid-row: 1;
  grid-column: 3;

  float: right;

  @media screen and (min-width: 1250px) {
    display: block;
  }
`;

const RightSidebarTitle = styled.p`
  margin: unset;
  padding: 0 0 0 14px;
  color: ${() => theme.blueDark1};
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;

const RightSidebarList = styled.ul`
  position: fixed;
  top: 64px;
  max-width: 560px;

  margin: 24px 24px 24px 0;
  padding: unset;

  &::before {
    position: absolute;

    top: 0;
    left: 0;
    width: 4px;
    height: calc(100% - 2px);
    background: ${() => theme.blueDark1};
    content: '';
  }

  & ul {
    list-style: none;
  }
`;

const RightSidebarListWrap = styled.nav`
  position: relative;
  max-height: calc(100vh - 64px - 24px - 26px - 24px);

  /* margin-left: -20px; */
  /* padding-left: 10px; */

  /* overflow-y: scroll; */
  direction: rtl;

  /* ${() => theme.scrollbar}; */
`;

const RightSidebarListItem = styled.li`
  position: relative;
  margin: 8px 0;
  color: ${() => theme.blueDark1}; 
  direction: ltr;
  text-align: left;
  list-style: none;

  &::before {
    position: absolute;

    top: 0;
    left: 0; 
    width: 4px;
    height: 100%;
    background: ${() => theme.blueLight1};
    opacity: 0;
    transition: opacity ${() => theme.transition03};
    content: '';
  }

  &.is-current {
    color: ${() => theme.blueLight1};

    &::before {
      opacity: 1;
    }
  }
`;

RightSidebar.propTypes = {
  tableOfContents: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default RightSidebar;
