import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';
import { sortBy, get } from 'lodash';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import NavItem from './NavItem';
import theme from "../../../../styles/theme";
import {GlobalDispatchContext} from "../../context/GlobalContextProvider";

/**
 * This File was inspired by https://github.com/hasura/gatsby-gitbook-starter
 */

const calculateTreeData = (edges, sidebarConfig, categoryName = 'docs') => {
  // console.log(categoryName);
  const originalData = sidebarConfig.ignoreIndex
    ? edges.filter(
        ({
          node: { fields: { slug } }
        }) => slug !== '/'
      )
    : edges;

  if (originalData.length === 0) {
    return { items: [] };
  }

  const tree = originalData
      .filter(item => {
        const slug = item.node.fields.slug;
        return slug.startsWith(`/${categoryName}`) && slug !== `/${categoryName}`;
      } )
      .reduce(
    (
      accu,
      edge
    ) => {
      const slug = edge.node.fields.slug;
      const title = edge.node.fields.title;
      const weight = edge.node.frontmatter.weight || 0;
      const parts = slug.split('/');

      let { items: prevItems } = accu;
      for (const part of parts.slice(2, -1)) {
        let tmp = prevItems.find(({ label }) => label === part);
        if (tmp) {
          if (!tmp.items) {
            tmp.items = [];
          }
        } else {
          tmp = { label: part, items: [] };
          prevItems.push(tmp);
        }
        prevItems = tmp.items;
      }
      const existingItem = prevItems.find(({ label }) => label === parts[parts.length - 1]);
      if (existingItem) {
        existingItem.url = slug;
        existingItem.title = title;
        existingItem.weight = weight;
      } else {
        prevItems.push({
          label: parts[parts.length - 1],
          url: slug,
          items: [],
          title,
          weight,
        });
      }
      return accu;
    },
    { items: [] }
  );

  const forcedNavOrder = sidebarConfig.forcedNavOrder || [];
  const tmp = [...forcedNavOrder];
  tmp.reverse();
  const result = tmp.reduce((accu, slug) => {
    const parts = slug.split('/');
    let { items: prevItems } = accu;
    for (const part of parts.slice(2, -1)) {
      let tmp = prevItems.find(({ label }) => label === part);
      if (tmp) {
        if (!tmp.items) {
          tmp.items = [];
        }
      } else {
        tmp = { label: part, items: [] };
        prevItems.push(tmp);
      }
      prevItems = tmp.items;
    }
    // sort items alphabetically.
    prevItems.forEach(item => {
      item.items = sortBy(item.items, ['weight', 'label']);
    });
    const index = prevItems.findIndex(({ label }) => label === parts[parts.length - 1]);
    accu.items.unshift(prevItems.splice(index, 1)[0]);
    return accu;
  }, tree);

  result.items = sortBy(result.items, ['weight', 'label']);
  return result;
};

const Navigation = ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const result = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              sidebarConfig {
                forcedNavOrder
                ignoreIndex
              }
            }
          }
        }
      }
      allMdx {
        edges {
          node {
            fields {
              slug
              title
            }
            frontmatter {
              weight
            }
          }
        }
      }
    }
  `);
  const { allSite, allMdx } = result;
  const { sidebarConfig } = allSite.edges[0].node.siteMetadata;

  const [scrollVal, setScrollVal] = useState(0);

  const treeData = useMemo(() => {
    if (typeof window !== "undefined") {
      const navList = document.querySelector("#navList"); 
      if (navList) {
        setScrollVal(navList.scrollTop);
      }
    }

    return calculateTreeData(allMdx.edges, sidebarConfig, get(location.pathname.split('/'), 1, ''));
  }, [allMdx.edges, sidebarConfig, location.pathname]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelector("#navList").scrollTo(0, scrollVal);
    }
    // console.log("navList Y:",  scrollVal);
  }, [location, scrollVal]);

  const isListReversed = useMemo(() => {
    const route = location.pathname.split('/').filter(Boolean)[0];
    return ['release_notes'].includes(route);
  }, [location]);

  useEffect(() => {
    const urlsToExpand = location.pathname.split('/').filter(Boolean).reduce((acc, item, i, arr) => {
      let urlPart = '';
      arr.forEach((itemNested, itemNestedIdx) => {
        if (itemNestedIdx <= i) {
          urlPart += `/${itemNested}`;
        }
      });
      acc.push(urlPart);

      return acc;
    }, []);

    urlsToExpand.slice(0, urlsToExpand.length - 1).forEach(itemUrl => {
      dispatch({ type: 'TOGGLE_NAV_EXPANDED', url: itemUrl });
    });
  }, []);

  return (
    <NavList id="navList">
      {treeData.items.map((treeItem) => (
        <NavItem {...treeItem} key={treeItem.url} isListReversed={isListReversed} />
      ))}
    </NavList>
  );
};

const NavList = styled.ul`
  position: relative;
  width: 272px;
  height: 100%;
  
  padding: 24px 24px 24px 40px;
  overflow-x: scroll;
  overflow-y: auto;
  list-style: none;
  background: ${() => theme.sidebarBackground};

  @media screen and (max-width: 1024px) {
    padding: 24px 24px 24px 32px;
  }
  @media screen and (max-width: 840px) {
    width: 236px;
    height: calc(100% - 64px);

    padding: 24px;
  }
  @media screen and (max-width: 550px) {
    width: 280px;
  }
  /* @media screen and (max-width: 600px) {
    margin: 0 0 0 16px;
  } */

  ${() => theme.scrollbar};

  &:after {
    position: absolute;

    top: 0;
    right: 0;
    width: 24px;
    height: 100%;
    background: ${() => theme.sidebarBackground};
    content: "";
  }
`;

export default React.memo(Navigation);
