import React from 'react';
import styled from 'styled-components';
import Logo from '../icons/Logo';
import theme from '../../../../styles/theme';
import { Link } from 'gatsby';

const LogoHeading = ({ showLogoTitle = true, isLogoClickable = true }) => {
  return (
    <LogoWrap to="/" clickable={String(isLogoClickable)}>
      <Logo logoStyle='light' />
        {showLogoTitle && (
            <>
                <LogoBorder />
                <LogoTxt>
                    Документация
                </LogoTxt>
            </>
        )}
    </LogoWrap>
  );
};

export const LogoWrap = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  
  cursor: ${({ clickable }) => clickable === 'true' ? 'pointer' : 'default'};
  pointer-events: ${({ clickable }) => clickable === 'true' ? 'all' : 'none'};
  
  > svg { 
    width: 120px;
  }
`;

export const LogoBorder = styled.div`
  position: relative;
  width: 2px;
  height: 32px;

  margin: 0 24px;
  background: ${() => theme.white};
`;

export const LogoTxt = styled.p`
  position: relative;
  margin: unset;
  color: ${() => theme.white};
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export default LogoHeading;
