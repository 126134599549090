import styled from 'styled-components';
import theme from '../../../../styles/theme';
import React from 'react';

const Svg = ({ children, viewBox, fill }) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} fill={fill}>
    {children}
  </StyledSvg>
);

const StyledSvg = styled.svg`
  transition: all ${() => theme.transition03};
  
  &:hover,
  &:focus {
    fill: ${() => theme.blueLight1};
  }
`;

export default Svg;
