import { createContext } from 'react';
import { User } from '@kvint/portal-auth';

export interface AuthContextData {
  user: User | null;
  token: string | null;
  authorize: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
  updateUserInfo: () => Promise<void>;
}

export const AuthContextDefaultData = {
  user: null,
  token: null,
  authorize: async () => {
    // eslint-disable-next-line no-console
    console.error('Not implemented!');
    return false;
  },
  logout: () => {
    // eslint-disable-next-line no-console
    console.error('Not implemented!');
  },
  updateUserInfo: async () => {
    // eslint-disable-next-line no-console
    console.error('Not implemented!');
  },
};

const AuthContext = createContext<AuthContextData>(AuthContextDefaultData);
export default AuthContext;
