import styled from 'styled-components';
import theme from '../../../../styles/theme';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable-next-line no-useless-escape */
const prepareTitle = (item) => item.title?.replace(/[\.\:\;\!\?]/g, '');

const ListItem = ({ location, item }) => {
  const itemTitle = prepareTitle(item);
  const nestedItems = item?.items || [];

  return (
      <>
          <ListItemLink className='anchor-link' href={location.pathname + item.url}>
              {itemTitle.charAt(0) + itemTitle.toLowerCase().slice(1)}
          </ListItemLink>
          {Boolean(nestedItems.length) && (
              <div style={{ marginLeft: '16px' }}>
                  {nestedItems.map(itemNested => {
                      const itemNestedTitle = prepareTitle(itemNested);
                      return (
                          <ListItemLink key={itemNested.url} className='anchor-link' href={location.pathname + itemNested.url}>
                              - {itemNestedTitle.charAt(0) + itemNestedTitle.toLowerCase().slice(1)}
                          </ListItemLink>
                      )
                  })}
              </div>
          )}
      </>
  );
};

const ListItemLink = styled.a`
  display: block;
  width: 100%;
  padding: 0 0 0 14px;
  color: inherit;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  transition: color ${() => theme.transition03};

  &:hover {
    color: ${() => theme.blueLight1};
  }
`;

ListItem.propTypes = {
  location: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default ListItem;
