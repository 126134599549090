import React from 'react';
import GlobalContextProvider from './src/components/docs-theme/context/GlobalContextProvider';
import AuthContextProvider from './src/components/docs-theme/context/auth/AuthProvider';

export const wrapRootElement = ({ element }) => (
    <GlobalContextProvider>
        <AuthContextProvider>
            {element}
        </AuthContextProvider>
    </GlobalContextProvider>
);
