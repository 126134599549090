import React from "react";
import Layout from "../components/docs-theme/components/Layout";
import Search from "../components/docs-theme/components/Search";

const Login = ({ location }) => (
    <Layout
        tableOfContents={[]}
        location={location}
        withoutSidebar
        withoutHeaderSearch
    >
        <Search />
    </Layout>
);

export default Login;