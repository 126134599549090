import styled from 'styled-components';
import theme from '../../../../styles/theme';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../context/GlobalContextProvider';
import ButtonCollapse from './ButtonCollapse';
import { sortBy } from 'lodash';

const NavigationItem = ({ items, url = '', title = '', isListReversed = false }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const isExpanded = state.expanded[url];
  const hasChildren = items && items.length > 0;

  const sortedItems = sortBy(items, ['weight', 'label']);

  return (
    <NavItem className={hasChildren && (title !== '') && 'hasChildren'} key={url}>
      {title !== '' && (
        <NavItemLink 
          to={url} 
          activeClassName="is-current anchor-link" 
          onClick={() => {
            dispatch({ type: isExpanded ? 'TOGGLE_NAV_COLLAPSED' : 'TOGGLE_NAV_EXPANDED', url: url });
          }}
          className={hasChildren && (title !== '') && 'hasChildren'}>
          {hasChildren && (title !== '') && (
            <ButtonCollapse isCollapsed={!isExpanded} />
          )}
          {title}
        </NavItemLink>
      )}
      {hasChildren && isExpanded && (
        <NavItemChild className={hasChildren && (title !== '') && 'hasChildren'} isReversed={isListReversed}>
          {sortedItems.map(item => (
            <NavigationItem
              key={item.url}
              {...item}
            />
          ))}
        </NavItemChild>
      )}
    </NavItem>
  );
};

const NavItemChild = styled.ul`
  display: flex;
  flex-direction: ${({ isReversed }) => isReversed ? 'column-reverse' : 'column'};
  position: relative;
  list-style: none;
`;

const NavItem = styled.li`
  position: relative;
  display: block;
  
  width: 100%;

  margin: unset;
  padding: 0 0 0 16px;
  list-style: none;
`;

const NavItemLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 24px 6px 0;
  color: ${() => theme.blueDark1};
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  text-decoration: none;
  transition: color ${() => theme.transition03};
  user-select: none;
  
  &:hover,
  &:focus,
  &.is-current {
    color: ${() => theme.blueLight1};

    > button > span > svg {
      fill: ${() => theme.blueLight1};
    }
  }

  &.hasChildren {
    font-weight: 500;

    & + ${NavItemChild}.hasChildren {
      padding-inline-start: unset;

      > ${NavItem} {
        &:not(.hasChildren) {
          padding: 0;

          > a {
            &::before {
              position: absolute;

              top: 50%;
              right: 100%;
              z-index: 1000;
              width: 2px;
              height: 100%;

              margin-right: 15px;
              background: ${() => theme.blueDark1};

              transform: translateY(-50%);
              opacity: .5;
              content: '';
            }
            &.is-current {
              &::before {
                background: ${() => theme.blueLight1};

                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;

export default React.memo(NavigationItem);
