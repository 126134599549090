import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { getSimilarWords } from '../../../../utilities/search';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from "@mdx-js/react";
import { BlogPostBody, BlogPostTitle } from './index.styled';

const ResultBody = ({ searchTerm, data }) => {
  const [similarWords, setSimilarWords] = useState([]);

  const { rawBody } = data;

  useEffect(() => {
    setSimilarWords(getSimilarWords(searchTerm, rawBody));
  }, [searchTerm, rawBody]);

  const highlightWords = (childrenProp) => {
    const result = [];
    React.Children.forEach(childrenProp, (child) => {
      if (child.props && child.props.children) {
        const newChild = React.cloneElement(child, {
          children: highlightWords(child.props.children),
          key: uniqueId(),
        });
        result.push(newChild);
      } else if (!child.props) {
        const parts = child.split(new RegExp(`(${similarWords.join('|')})`, 'gi'));
        const highlightedChild = parts.map((part) => {
          if (part !== '') {
            if (similarWords.includes(part.toLowerCase())) {
              return (
                <mark className="highlight" key={uniqueId()}>
                  {part}
                </mark>
              );
            }
            return part;
          }
          return null;
        });
        result.push(highlightedChild);
      } else {
        result.push(child);
      }
    });
    return result;
  };

  const shortcodes = {
    wrapper: searchTerm === '' ? null : ({ children: mdxChildren }) => <>{highlightWords(mdxChildren)}</>,
  };

  // useEffect(() => {
  //   const highlights = document.querySelectorAll('.highlight');
  //   if (highlights[0]) {
  //     highlights[0].scrollIntoView({ block: "center", behavior: "smooth" });
  //   }
  // }, [searchTerm, shortcodes]);

  return (
    <>
      <BlogPostTitle>{highlightWords(data.title)}</BlogPostTitle>
      <BlogPostBody>
        <MDXProvider components={shortcodes}><MDXRenderer>{data.body}</MDXRenderer></MDXProvider>
      </BlogPostBody>
    </>
  );
};


export { ResultBody as default };
