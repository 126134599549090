// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-docs-theme-templates-docs-tsx": () => import("./../../../src/components/docs-theme/templates/docs.tsx" /* webpackChunkName: "component---src-components-docs-theme-templates-docs-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

