import styled from 'styled-components';
import theme from '../../../../styles/theme';
import PropTypes from 'prop-types';
import React from 'react';
import Navigation from './Navigation';
import Logo from '../icons/Logo';

const LeftSidebar = ({ leftNavOpen, setLeftNavOpen, location }) => {
  return (
    <LeftSidebarStyled leftNavOpen={leftNavOpen}>
      <LeftSidebarHeader>
        <LeftSidebarClose 
          role="button" 
          tabIndex={0} 
          aria-hidden="true"
          onClick={() => {
            setLeftNavOpen(!leftNavOpen);
          }}
        />
        <Logo logoStyle='light'/>
      </LeftSidebarHeader>
      <Navigation location={location} />
    </LeftSidebarStyled>
  );
};

const LeftSidebarStyled = styled.nav`
  position: fixed;
  
  top: 64px;
  bottom: 0;
  z-index: 11;
  height: calc(100vh - 64px);
  transition: ${() => theme.transition03};

  @media screen and (max-width: 840px) {
    top: 0;
    height: 100vh;
    transform: ${p => (p.leftNavOpen ? 'translateX(0)' : 'translateX(-290px)')};

    &::before {
      position: fixed;

      top: 64px;
      left: 236px;
      z-index: 1;
      width: 100vw;
      height: calc(100vh - 64px);
      background: ${() => theme.black};

      visibility: ${p => (p.leftNavOpen ? `visible` : `hidden`)};
      opacity: ${p => (p.leftNavOpen ? 0.15 : 0)};
      transition: ${() => theme.transition03};
      content: '';
      pointer-events: ${p => (p.leftNavOpen ? `visible` : 'none')};

      @media screen and (max-width: 550px) {
        left: 280px;
      }  
    }
  }
`;

const LeftSidebarHeader = styled.div`
  @media screen and (min-width: 840px) {
    display: none;
  }
  
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;

  padding: 0 40px;
  background: ${() => theme.blueDark1};

  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 840px) {
    padding: 0 24px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }

  > svg {
    width: 120px;
  }
`;

const LeftSidebarClose = styled.div`
  position: relative;
  width: 44px;
  height: 44px;

  margin-right: 24px;

  @media screen and (max-width: 600px) {
    margin-right: 16px;
  }

  &::before,
  &::after {
    position: absolute;
    
    top: 50%;
    left: 50%;
    width: 38px;
    height: 4px;
    
    margin-right: -50%;
    background: ${() => theme.white};

    border-radius: 10px;
    content: '';
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }   
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

LeftSidebar.propTypes = {
  leftNavOpen: PropTypes.bool
};

export default React.memo(LeftSidebar);
