import React from 'react';
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

const domainRegex = /http[s]*:\/\/[www.]*academy\.kvint\.io[/]?/;

const MarkdownLink = ({ href, ...rest }) => {
    const sameDomain = domainRegex.test(href);

    if (href.startsWith('/') || href.startsWith('#')) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a href={href} {...rest} />
    }

    if (sameDomain) {
        return <GatsbyLink data-link-internal to={href.replace(domainRegex, '/')} {...rest} />
    }

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={href} {...rest} target="_blank" rel="noreferrer" />;
}

MarkdownLink.propTypes = {
    href: PropTypes.string.isRequired,
}

export default MarkdownLink