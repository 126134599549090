import React from 'react'; 
import Svg from './SVG';
import theme from '../../../../styles/theme';
import { ThemeProvider } from 'styled-components'; 

// viewBox="0 0 166.23 47.939"
const Logo = ({ logoStyle }) => (
  <Svg viewBox='0 0 161 41'>
    <path d='M46.9653 23.9062H29.3643V25.4218H46.9653V23.9062Z' fill='#3FECFF' /> 
    <path d='M50.17 40.3202H20.0623C8.98653 40.3202 0 31.3125 0 20.2745C0 9.2365 8.98653 0.200195 20.0623 0.200195H30.1077C41.1835 0.200195 50.17 9.2079 50.17 20.2459V40.3202ZM20.0623 10.1802C14.5101 10.1802 9.98821 14.6983 9.98821 20.2459C9.98821 25.7935 14.5101 30.3117 20.0623 30.3117H40.2104V20.2459C40.2104 14.6983 35.6885 10.1802 30.1364 10.1802H20.0623Z' fill='url(#paint0_linear)' /> 
    <path opacity='0.5' d='M40.1815 30.3402V33.5715C40.1815 37.289 37.1764 40.2916 33.4559 40.2916H21.2926C18.3734 40.3488 16.37 40.12 13.8515 39.3193C13.5653 39.2335 13.1933 39.0905 12.7926 38.9476C10.6175 38.1183 6.95422 36.231 3.92055 32.1703C2.80438 30.6834 1.74546 28.7674 1.05859 26.6799C1.05859 26.6799 9.15792 33.1712 20.062 30.3402H40.1815Z' fill='url(#paint1_linear)' /> 
    <path d='M31.453 26.4228H29.1921C28.219 26.4228 27.4463 25.6221 27.4463 24.6784C27.4463 23.7062 28.2476 22.9341 29.1921 22.9341H31.453C32.4261 22.9341 33.1988 23.7348 33.1988 24.6784C33.1988 25.6221 32.4261 26.4228 31.453 26.4228Z' fill='url(#paint2_linear)' /> 
    <path d='M54.7207 0H61.6466V24.3637L70.8621 15.3846H79.8486L68.315 26.1652L80.7645 39.9771H71.549L62.3907 29.4824L61.6466 30.1687V40.0056H54.7207V0Z' fill={logoStyle === 'dark' ? theme.blueDark1 : theme.white} /> 
    <path d='M80.0781 15.3845H87.8054L94.2734 29.2535L100.741 15.3845H108.469L96.0764 39.977H92.4418L80.0781 15.3845Z' fill={logoStyle === 'dark' ? theme.blueDark1 : theme.white} /> 
    <path d='M109.899 15.3845H116.825V39.977H109.899V15.3845Z' fill={logoStyle === 'dark' ? theme.blueDark1 : theme.white} /> 
    <path d='M118.771 39.9771V15.3847H125.697V18.5588C127.586 15.8994 130.133 14.584 133.31 14.584C136.544 14.584 138.948 15.5848 140.58 17.5866C141.867 19.1879 142.526 21.4756 142.526 24.5068V39.9485H135.6V26.5371C135.6 24.2208 135.17 22.6194 134.283 21.7044C133.482 20.8751 132.394 20.4461 131.049 20.4461C129.16 20.4461 127.729 21.161 126.785 22.5908C126.069 23.7347 125.697 25.3074 125.697 27.3664V39.92H118.771V39.9771Z' fill={logoStyle === 'dark' ? theme.blueDark1 : theme.white} /> 
    <path d='M160.269 21.2755H155.49V39.9486H148.564V21.2755H145.845V15.3847H148.564V6.97754H155.49V15.3847H160.269V21.2755Z' fill={logoStyle === 'dark' ? ThemeProvider.dark : theme.white} /> 
    <defs> 
      <linearGradient id='paint0_linear' x1='-0.0204916' y1='20.2522' x2='50.1744' y2='20.2522' gradientUnits='userSpaceOnUse'> 
        <stop stopColor='#0091BF' /> 
        <stop offset='1' stopColor='#3FECFF' /> 
      </linearGradient> 
      <linearGradient id='paint1_linear' x1='1.0355' y1='33.4968' x2='40.19' y2='33.4968' gradientUnits='userSpaceOnUse'>  
        <stop stopColor='#3FECFF' /> 
        <stop offset='1' stopColor='#0091BF' /> 
      </linearGradient> 
      <linearGradient id='paint2_linear' x1='27.4258' y1='24.6586' x2='33.2049' y2='24.6586' gradientUnits='userSpaceOnUse'> 
        <stop stopColor='#0091BF' /> 
        <stop offset='1' stopColor='#3FECFF' /> 
      </linearGradient> 
    </defs>
  </Svg>
);

export default Logo;