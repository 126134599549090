import React, {useContext} from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { isLoggedIn, logout } from "../../../../services/auth"
import IconButton, { IconButtonStyled } from './../icons/IconButton';
import Menu from './../icons/Menu';
import theme from '../../../../styles/theme';
import LogoHeading, { LogoBorder, LogoTxt } from './LogoHeading';
import HeaderSearch from './HeaderSearch';
import AuthContext from "../../context/auth/context";

const Header = ({
  leftNavOpen,
  setLeftNavOpen,
  withoutSidebar,
  withoutHeaderSearch,
  withoutLoginBtn = false,
  showLogoTitle = true,
  isLogoClickable = true,
}) => {
  const { user, logout } = useContext(AuthContext);
  return (
    <HeaderStyled>
      <HeaderInner>
        <HeaderLeft>
          {!withoutSidebar &&
            <IconButton
              label="Open Navigation"
              icon={<Menu fill={theme.white} />}
              size={44}
              onClick={() => {
                setLeftNavOpen(!leftNavOpen);
              }}
            />  
          }
          <LogoHeading showLogoTitle={showLogoTitle} isLogoClickable={isLogoClickable} />
        </HeaderLeft>

        {!withoutLoginBtn && (
            user ? (
                  <Link
                      style={{ display: 'flex', marginLeft: 'auto', marginRight: '24px' }}
                      to="/"
                      onClick={event => {
                        // event.preventDefault()
                        logout();
                        // navigate(`/`);
                      }}
                  >
                    Logout
                  </Link>
              ) : (
                  <Link
                      style={{ display: 'flex', marginLeft: 'auto', marginRight: '24px' }}
                      to="/login"
                      // onClick={event => {
                      //   event.preventDefault();
                      //   navigate(`/login`);
                      // }}
                  >
                    Login
                  </Link>
              )
        )}
        {!withoutHeaderSearch && <HeaderSearch /> }

        {/* <button onClick={() => navigate(`/search?value=голос`)}>Go</button> */}
        {/* <HeaderRight>
          <IconButton
            label="Open Navigation"
            icon={<Menu fill={theme.blueLight1}/>}
            size={44}
            onClick={() => {
              setRightNavOpen(!RightNavOpen);
            }}
          />
        </HeaderRight> */}
      </HeaderInner>
    </HeaderStyled>
  );
};

const HeaderStyled = styled.header`
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${() => theme.blueDark1};
  transition: ${() => theme.transition03};

  @media screen and (min-width: 1024px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 840px) {
    padding: 0 24px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 64px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  ${IconButtonStyled} {
    margin-right: 24px;

    @media screen and (min-width: 840px) {
      display: none;
    }
    @media screen and (max-width: 600px) {
      margin-right: 16px;
    }
  }
  ${LogoBorder}, 
  ${LogoTxt} {
    @media screen and (max-width: 550px) {
      display: none;
    }
  }
`;

Header.propTypes = {
  leftNavOpen: PropTypes.bool,
  setLeftNavOpen: PropTypes.func,

  rightNavOpen: PropTypes.bool,
  setRightNavOpen: PropTypes.func,
};

export default Header;
