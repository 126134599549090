import styled from 'styled-components';
import React from 'react';
import theme from '../../../../styles/theme';
import Icon from './Icon';

const IconButton = ({ onClick, label, icon, size }) => {
  return (
    <IconButtonStyled type="button" onClick={onClick} aria-label={label} title={label}>
      <Icon icon={icon} size={size} />
    </IconButtonStyled>
  );
};

export const IconButtonStyled = styled.button`
  padding: 0;
  color: ${() => theme.blueDark1};
  background: none;

  border: none;
  cursor: pointer;
  
  &:hover {
    color: ${() => theme.primary};
  }
`;

export default IconButton;
